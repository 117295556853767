import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Profile_image_bank_pagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyprofile_image_bankInput
    $sort: SortFindManyprofile_image_bankInput
  ) {
    profile_image_bank_pagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        image_url
        name
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Profile_image_bank_findById($id: MongoID!) {
    profile_image_bank_findById(_id: $id) {
      image_url
      name
      _id
      updatedAt
      createdAt
    }
  }
`;

export const CREATE = gql`
  mutation Profile_image_bank_createOne(
    $record: CreateOneprofile_image_bankInput!
  ) {
    profile_image_bank_createOne(record: $record) {
      recordId
      record {
        image_url
        name
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const EDIT = gql`
  mutation Profile_image_bank_updateById(
    $id: MongoID!
    $record: UpdateByIdprofile_image_bankInput!
  ) {
    profile_image_bank_updateById(_id: $id, record: $record) {
      recordId
      record {
        image_url
        name
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_MANY = gql`
  mutation Profile_image_bank_removeMany(
    $filter: FilterRemoveManyprofile_image_bankInput!
  ) {
    profile_image_bank_removeMany(filter: $filter) {
      numAffected
      error {
        message
      }
    }
  }
`;
export const DELETE_ID = gql`
  mutation Profile_image_bank_removeById($id: MongoID!) {
    profile_image_bank_removeById(_id: $id) {
      recordId
      record {
        image_url
        name
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;
