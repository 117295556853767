import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Stickers_pagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManystickersInput
    $sort: SortFindManystickersInput
  ) {
    stickers_pagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        content
        name
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Stickers_findById($id: MongoID!) {
    stickers_findById(_id: $id) {
      content
      name
      _id
      updatedAt
      createdAt
    }
  }
`;

export const CREATE = gql`
  mutation Stickers_createOne($record: CreateOnestickersInput!) {
    stickers_createOne(record: $record) {
      recordId
      record {
        content
        name
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const EDIT = gql`
  mutation Stickers_updateById(
    $id: MongoID!
    $record: UpdateByIdstickersInput!
  ) {
    stickers_updateById(_id: $id, record: $record) {
      recordId
      record {
        content
        name
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_MANY = gql`
  mutation Stickers_removeMany($filter: FilterRemoveManystickersInput!) {
    stickers_removeMany(filter: $filter) {
      numAffected
      error {
        message
      }
    }
  }
`;
export const DELETE_ID = gql`
  mutation Stickers_removeById($id: MongoID!) {
    stickers_removeById(_id: $id) {
      recordId
      record {
        content
        name
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;
