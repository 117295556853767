import {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';

import React from 'react';
import EditImage from './EditImage';

export function Editor({ image, isOpen, setIsOpen, onChange }) {
  const { onClose } = useDisclosure();

  const handleOnChange = (file) => {
    console.log('🚀 ~ handleOnChange 22222222222222', file);
    onClose();
    onChange(file);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={'6xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <Text>Image Editor</Text>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <EditImage
              image={image}
              onChange={handleOnChange}
              onClose={() => setIsOpen(false)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
