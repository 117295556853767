const links = [
  {
    group: 'Feed',
    link: '/feed',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Schedule',
    link: '/calendars',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Surveys',
    link: '/surveys',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Documents',
    link: '/documents',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Jobs',
    link: '/jobs',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Posts',
    link: '/posts',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Messages',
    items: [
      {
        title: 'Messages',
        href: '/messages',
      },
      // {
      //   title: 'Message Categories',
      //   href: '/messagecategories',
      // },
    ],
    permissions: ['view-business'],
  },
  {
    group: 'Staff Members',
    link: '/users',
    items: [],
    permissions: ['view-business'],
  },
  {
    group: 'Notifications',
    link: '/notifications',
    items: [],
    permissions: ['view-business'],
  },

  {
    group: 'Settings',
    items: [
      {
        title: 'Emojis',
        href: '/emojis',
      },

      {
        title: 'Comments',
        href: '/comments',
      },
      {
        title: 'Stickers',
        href: '/stickers',
      },
      {
        title: 'Profile Avatars',
        href: '/avatars',
      },
      {
        title: 'Branches',
        href: '/branches',
      },
      {
        title: 'Add Admin',
        href: '/admins',
      },
    ],
    permissions: ['Root'],
  },
];

export default links;
