import { useMutation, useLazyQuery } from '@apollo/client';
import {
  Text,
  Flex,
  Grid,
  Stack,
  TabPanel,
  Tabs,
  useToast,
  Box,
} from '@chakra-ui/react';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useWatch } from 'react-hook-form';

import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { EDIT, FETCH, CREATE, EDIT_STATIC_PAGE, DELETE_ID } from './graphql';
import { setup } from './setup';
import FeedFields from '../../components/Feed/Fields';
import ScheduleNotification from '../../components/ScheduleNotification';

import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';

import CommentsSelect from './components/CommentsSelect';
import MediaViewer from './components/MediaViewer';

const Index = ({ title }) => {
  const params = useParams();

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);
  const toast = useToast();

  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(FETCH, {
    // pollInterval: 3000,
  });

  const [
    editStaticPage,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT_STATIC_PAGE);

  useEffect(() => {
    if (editData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editError) {
      toast({
        title: 'Error, try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [editData, editError]);

  return (
    <Meta title={`${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_ID}
        ignoreValues={['reactions']}
        gqlString={'posts_createOne'}
      >
        <Grid templateColumns={type === 'Edit' ? '1fr 300px' : '1fr'}>
          <Flex direction="column">
            <Tabs
              orientation="vertical"
              w="100%"
              m="0"
              p="0"
              ml="-40px"
              align="start"
            >
              <Grid
                templateColumns={{ base: '1fr' }}
                w="100%"
                maxWidth="1000px"
                gap="25px"
              >
                <Stack spacing="20px" w="100%">
                  <Stack spacing="20px" w="100%">
                    <FormSection>
                      <FormSectionInner>
                        <FeedFields
                          targetID={data?.posts_findById?.feed?.staticpage?._id}
                          feed={data?.posts_findById?.feed}
                          refetch={refetch}
                        />
                        {type === 'Edit' && (
                          <>
                            {/* Note: Image  is saved in Staticpage_updateById "hero.imageUrl" */}

                            <Field
                              component="file"
                              name="feed.staticpage.content"
                              placeholder="Image / Video *"
                              customUpdate={editStaticPage}
                              disabled={editLoading}
                              targetProperty="content"
                              targetID={data?.posts_findById?.feed?.staticpage}
                              multiple
                              targetKey={'_id'}
                            />

                            <MediaViewer
                              staticPage={
                                data?.posts_findById?.feed?.staticpage
                              }
                              loading={loading}
                              updateFile={editStaticPage}
                            />

                            {/* Note: Body is saved in Staticpage_updateById "seo" */}
                            <Field
                              component="richtext"
                              name="feed.staticpage.seo"
                              defaultValue={
                                data?.posts_findById?.feed?.staticpage?.seo
                              }
                              placeholder="Body"
                              rules={{ required: false }}
                              customUpdate={editStaticPage}
                              disabled={editLoading}
                              targetProperty="seo"
                              targetID={
                                data?.posts_findById?.feed?.staticpage?._id
                              }
                            />

                            {data?.posts_findById?.feed && (
                              <CommentsSelect
                                feed={data?.posts_findById?.feed}
                                refetch={refetch}
                              />
                            )}
                          </>
                        )}
                      </FormSectionInner>
                    </FormSection>
                  </Stack>
                </Stack>
              </Grid>

              {type === 'Edit' && (
                <TabPanel>
                  <ScheduleNotification id={params?.id} />
                </TabPanel>
              )}
            </Tabs>
          </Flex>
          {type === 'Edit' && (
            <Box w="300px" overflow={'hidden'}>
              <Preview />
            </Box>
          )}
        </Grid>
      </EditView>
    </Meta>
  );
};

const Preview = () => {
  const values = useWatch();
  return (
    <Flex
      direction={'column'}
      bg={values?.feed?.type === 'Survey' ? '#329ae3' : 'white'}
      py="1rem"
      w="300px"
      mt="134px"
      h="fit-content"
      position={'fixed'}
    >
      <Flex w="100%" justifyContent={'space-between'} px="1rem">
        <Text
          as={'small'}
          bg={values?.feed?.type === 'Job' ? 'red' : '#cfbebe'}
          color={values?.feed?.type === 'Job' ? 'white' : 'black'}
          borderRadius={'15px'}
          px="5px"
          align={'center'}
        >
          Post
        </Text>
        <Text
          as={'small'}
          px="5px"
          align={'center'}
          color={values?.feed?.types === 'Survey' ? 'white' : 'black'}
        >
          {dayjs(values?.feed?.createdAt).fromNow()}
        </Text>
      </Flex>

      <Flex
        direction={'row'}
        justifyContent={'start'}
        alignItems={'center'}
        px="1rem"
      >
        <Text as="strong" my="5px">
          {values?.feed?.title}
        </Text>
      </Flex>

      <ImageCarousel staticpage={values?.feed?.staticpage} />

      <Box p="1rem" color={'#333'}>
        <div
          dangerouslySetInnerHTML={{ __html: values?.feed?.staticpage?.seo }}
        ></div>
      </Box>
    </Flex>
  );
};

export default Index;
